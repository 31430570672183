function ScrollTo(e, s, h) {
    h = typeof h !== 'undefined' ? h : 0;
    e = $(e);
    //if(!h){
    //    //h = $('#header').height();
    //    h = 0;
    //}

    if ($(window).width() < 769) {
        h = 0;
    }

    $('html, body').animate({
        scrollTop: (e.offset().top - h )
    }, s);
}
